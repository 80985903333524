// 统一方法, 发送统计消息
// 支持第三方友盟和平台(预留)

export function sendStatEvent(eventName, value) {
  const { aplus_queue } = window;
  const params = isJSONString(value) ? JSON.parse(value) : value;
  // 友盟只支持 _stat_ 字段, 其他字段不处理
  const filterParams = {};
  for (const key in params) {
    if (key.includes("_stat_")) {
      filterParams[key] = params[key];
    }
  }

  // 设置_hold=START后，事先被block住的日志会携带上用户信息逐条发出
  aplus_queue.push({
    action: "aplus.setMetaInfo",
    arguments: ["_hold", "START"],
  });

  aplus_queue.push({
    action: "aplus.record",
    arguments: [eventName, "CLK", filterParams],
  });

  console.log([eventName, "CLK", filterParams]);

  // // 发送统计消息到后台
  // fetch('https://apiv2.iting.chat/api/api-product/device/thingEventPost', {
  //   method: 'POST',
  //   mode: 'cors',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     deviceToken: localStorage.getItem('AIOFFICE_TOKEN'),
  //     code: eventName,
  //     params: params
  //   })
  // })
  //   .then((response) => {
  //     console.log(response, 'response');
  //   })
  //   .catch((error) => {
  //     console.error('Error:', error);
  //   });
}

// 校验是否json字符串
export function isJSONString(str) {
  if (typeof str !== "string") {
    return false;
  }
  try {
    const firstChar = str.trim()[0];
    const lastChar = str.trim()[str.length - 1];

    if (
      (firstChar === "{" && lastChar === "}") ||
      (firstChar === "[" && lastChar === "]")
    ) {
      JSON.parse(str);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
// 将 sendStatEvent 方法添加到 window，以便 C++ 调用
window["sendStatEvent"] = sendStatEvent;
