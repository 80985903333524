<template>
  <div class="swiper">
    <swiper
      :options="swiperOption"
      class="swiper-container"
      ref="bannerSwiper"
      @someSwiperEvent="callback"
    >
      <!-- 轮播图组-新 -->
      <template v-if="['xunxiang'].includes(currentHost)">
        <swiper-slide v-for="(image, index) in swiperImages" :key="index">
          <img :src="image" alt="Image" />
        </swiper-slide>
      </template>

      <swiper-slide
        v-if="
          ![
            'yundesk',
            'wo',
            'tele',
            'jiyuai',
            'm70',
            'p70',
            'lefan',
            'pansheng',
            'newland',
            'i70',
            'm7',
            'miaowang',
            'mifi',
            'yff',
            'zuoh',
            'xunxiang',
            'hbs',
            'www.hbs',
            'zxhy',
            'beite',
            'keliumao',
          ].some((host) => currentHost.includes(host))
        "
      >
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">智能办公 AI Office</h3>
                <p class="banner-intro">
                  <span class="color-o font-w600">让工作更轻松高效 </span>
                </p>
                <p class="brief-explain m-top">
                  智慧办公————以人工智能技术为基石，<br />
                  通过软硬件结合产品落地到办公场景，打造新一代<br />
                  “简单、高效、友好”办公场景，提升人类工作效率和幸福感
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">了解更多</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-office.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some((host) => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">
                  智能语音鼠标-<span class="color-o">V8</span>
                </h3>
                <p class="banner-intro">
                  一分钟可打<span class="color-o font-w600">400字</span>
                </p>
                <p class="brief-explain m-top">
                  语音打字 | 100+全球语言 | 24种方言
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">了解更多</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-mouse.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide
        v-if="['www', 'app', 'baseus'].some((host) => host === webHost)"
      >
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">
                  智能翻页演讲笔<span class="color-o"></span>
                </h3>
                <p class="banner-intro">
                  <span class="color-o font-w600">实时</span>录音、翻译字幕
                </p>
                <p class="brief-explain m-top">
                  语音打字 | 100+全球语言 | 24种方言
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">了解更多</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-fyb.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some((host) => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">
                  智能语音键盘<span class="color-o"></span>
                </h3>
                <p class="banner-intro">
                  能<span class="color-o font-w600">“说话打字”</span>操控的键盘
                </p>
                <p class="brief-explain m-top">
                  语音打字 | 智能搜索 | 翻译打字 | 智能M键
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">了解更多</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-keyboard.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some((host) => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">
                  录音翻译会议耳机-<span class="color-o">A03</span>
                </h3>
                <p class="banner-intro">
                  <span class="color-o font-w600">电话录音 会议APP录音</span>
                </p>
                <p class="brief-explain m-top">
                  通话翻译 | 通话录音 | 来电报姓名
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">了解更多</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-ej.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some((host) => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">
                  智能录音笔-<span class="color-o">R3/R5</span>
                </h3>
                <p class="banner-intro">
                  边<span class="color-o font-w600">写</span>边<span
                    class="color-o font-w600"
                    >录</span
                  >
                  出口成章
                </p>
                <p class="brief-explain m-top">
                  高清录音 | 语音速记 | 一键分享
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">了解更多</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-record.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="['www', 'app'].some((host) => host === webHost)">
        <a href="javascript:;">
          <div class="banner-bg">
            <div class="banner-content">
              <div class="banner-text">
                <h3 class="banner-title">
                  智能全向麦<span class="color-o"></span>
                </h3>
                <p class="banner-intro">
                  会议室<span class="color-o font-w600">“伴侣”</span>
                </p>
                <p class="brief-explain m-top">
                  会议纪要 | 纪要分享 | 通话录音 | 通话翻译
                </p>
                <div>
                  <!-- <button class="more-btn font-w600">了解更多</button> -->
                </div>
              </div>
              <div class="banner-product">
                <img src="../../assets/images/ai-img-qxm.png" />
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
      <!-- 云电脑 -->
      <swiper-slide v-if="currentHost == 'yundesk'">
        <a href="javascript:;">
          <div class="banner-bg-yundesk"></div>
        </a>
      </swiper-slide>
      <!-- wo -->
      <swiper-slide v-if="currentHost == 'wo'">
        <a href="javascript:;">
          <div class="banner-bg-wo"></div>
        </a>
      </swiper-slide>
      <!-- tele -->
      <swiper-slide v-if="currentHost == 'tele'">
        <a href="javascript:;">
          <div class="banner-bg-tele"></div>
        </a>
      </swiper-slide>
      <!-- jiyuai -->
      <swiper-slide v-if="currentHost == 'jiyuai'">
        <a href="javascript:;">
          <div class="banner-bg-jiyuai1"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="currentHost == 'jiyuai'">
        <a href="javascript:;">
          <div class="banner-bg-jiyuai2"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="currentHost == 'jiyuai'">
        <a href="javascript:;">
          <div class="banner-bg-jiyuai3"></div>
        </a>
      </swiper-slide>
      <!-- m70 -->
      <swiper-slide v-if="currentHost == 'm70'">
        <a href="javascript:;">
          <div class="banner-bg-m70"></div>
        </a>
      </swiper-slide>
      <!-- p70 -->
      <swiper-slide v-if="currentHost == 'p70'">
        <a href="javascript:;">
          <div class="banner-bg-p70"></div>
        </a>
      </swiper-slide>
      <!-- lefan -->
      <swiper-slide v-if="currentHost == 'lefan'">
        <a href="javascript:;">
          <div class="banner-bg-lefan"></div>
        </a>
      </swiper-slide>
      <!--   170 -->
      <swiper-slide v-if="currentHost == 'i70'">
        <a href="javascript:;">
          <div class="banner-bg-i70"></div>
        </a>
      </swiper-slide>
      <!-- pansheng -->
      <swiper-slide v-if="currentHost == 'pansheng'">
        <a href="javascript:;">
          <div class="banner-bg-pansheng"></div>
        </a>
      </swiper-slide>
      <!-- newland -->
      <swiper-slide v-if="currentHost == 'newland'">
        <a href="javascript:;">
          <div class="banner-bg-newland"></div>
        </a>
      </swiper-slide>
      <!-- m7-->
      <swiper-slide v-if="currentHost == 'm7'">
        <a href="javascript:;">
          <div class="banner-bg-m7"></div>
        </a>
      </swiper-slide>
      <!-- miaowang-->
      <swiper-slide v-if="currentHost == 'miaowang'">
        <a href="javascript:;">
          <div class="banner-bg-miaowang"></div>
        </a>
      </swiper-slide>
      <!-- mifi -->
      <swiper-slide v-if="currentHost == 'mifi'">
        <a href="javascript:;">
          <div class="banner-bg-mifi"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="currentHost == 'yff'">
        <a href="javascript:;">
          <div class="banner-bg-yff"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="currentHost == 'zuoh'">
        <a href="javascript:;">
          <div class="banner-bg-zuoh"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="webHost.includes('hbs')">
        <a href="javascript:;">
          <div class="banner-bg-hbs"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="webHost.includes('zxhy')">
        <a href="javascript:;">
          <div class="banner-bg-zxhy"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="webHost.includes('beite')">
        <a href="javascript:;">
          <div class="banner-bg-beite"></div>
        </a>
      </swiper-slide>
      <swiper-slide v-if="webHost.includes('keliumao')">
        <a href="javascript:;">
          <div class="banner-bg-keliumao"></div>
        </a>
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination">
      <!-- <div class="page"></div> -->
    </div>
    <!-- 分页 -->
  </div>
</template>

<script>
import Swiper from "swiper";
import store from "@/store/index.js";
export default {
  name: "carrousel",
  data() {
    return {
      swiperImages: [],
      swiperOption: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false,
        },
        effect: "slide",
        sliderPerView: 6,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          dynamicBullets: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      },
      currentHost: "",
      webHost: "",
    };
  },
  computed: {
    swiper() {
      return this.$refs.bannerSwiper.$swiper; //这里需要加上$  $swiper
    },
  },
  created() {
    const name = store.getters["getCurrentHost"];
    this.currentHost = name;
    this.webHost = store.getters["getWebHost"];

    // 获取轮播图组
    if (["xunxiang"].includes(this.currentHost)) {
      this.swiperImages = this.getImagesForSwiper("xunxiang");
    }
  },
  mounted() {
    console.log("current", this.swiper);
    new Swiper(".swiper-container", {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 8000,
        disableOnInteraction: true,
      },
      // 如果需要分页器
      pagination: ".swiper-pagination",
      // 如果需要前进后退按钮
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      // 如果需要滚动条
      scrollbar: ".swiper-scrollbar",
    });
    //this.$swiper.slideTo(1,3000,true)
  },
  methods: {
    callback() {},
    // 获取assets静态资源
    getAssetsFile(name, folder, type = "png") {
      const context = require.context(
        "../../assets/images",
        true,
        /\.(png|svg|jpg|jpeg)$/
      );
      const path = folder ? `./${folder}/${name}.${type}` : `./${name}.${type}`;
      const asset = context.keys().find((key) => key.includes(path));
      return asset ? context(asset) : null;
    },

    // 获取指定文件夹中的所有图片
    getImagesForSwiper(folder) {
      let context;
      switch (folder) {
        case "xunxiang":
          context = require.context(
            "../../assets/swiper/xunxiang",
            true,
            /\.(png|jpg|jpeg|svg)$/
          );
          break;

        default:
          return [];
      }

      // 获取所有图片的路径
      const images = context.keys().map((key) => context(key));
      return images;
    },
  },
};
</script>

<style></style>
